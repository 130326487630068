@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&display=swap");
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mulish", sans-serif;
}

::-webkit-scrollbar {
  width: 0px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
